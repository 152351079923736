<template>
  <section class="section section-standalone" id="emonev">
    <div class="container-fluid my-3">
      <div class="c-dashboard-title d-flex justify-content-between pl-2">
        <h1 class="title-font text-size-title text-uppercase mb-0">
          Realisasi Anggaran
        </h1>
        <div class="c-dashboard-title-option d-flex align-items-center">
          <select
            class="form-control form-control-sm mr-2 form-control-custom"
            v-model="tahun"
            v-on:change="handleChangeTahun()"
          >
            <option :value="item" v-for="item in arrayTahun" :key="item">
              {{ item }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-12 doc" v-if="loadComponent">
          <iframe
            :src="iframeUrl"
            scrolling="yes"
            frameborder="0"
            id="emonev-iframe"
            width="100%"
            height="700px"
            ref="frame"
          />
        </div>
      </div>
      <div
        v-if="loadComponent"
        class="row sumber-data"
        v-tooltip.top-start="{
          content: `Pic : ${data.pic} <br> Kontak : ${data.kontak}`,
          show: dataTooltip,
          trigger: 'manual'
        }"
        style="cursor:pointer"
        v-on:mouseover="changeDataTooltip"
        v-on:click="changeDataTooltip"
      >
        <div class="col-md-12 " v-if="loadComponent">
          <p class="text-size-normal ">
            &nbsp; Sumber Data : {{ data.sumber_data }} <br />
            &nbsp; Pembaharuan Terakhir : {{ data.last_update_realisasi }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
let date = new Date(window.date_now);
export default {
  data() {
    return {
      tahun: date.getFullYear(),
      loadComponent: false,
      iframeUrl: null,
      arrayTahun: window.date_years,
      data: {
        pic: null,
        kontak: null,
        sumber_data: null,
        last_update_realisasi: null
      },
      dataTooltip: false
    };
  },
  created() {
    this.getLasUpdateRealisasi();
    this.generateUrl();
  },
  methods: {
    async getLasUpdateRealisasi() {
      const apiUrl = `${window.emonev_api}/api/iframe/last-update-realisasi`;
      const { data } = await window.axios.get(apiUrl);
      this.data = data.data;
    },
    async generateUrl() {
      try {
        const username = process.env.VUE_APP_MANPRO_USERNAME;
        const password = process.env.VUE_APP_MANPRO_PASSWORD;
        const apiUrl = `${window.emonev_api}/api/iframe/generate-url`;
        const tahun = this.tahun;
        const params = {
          username,
          password,
          tahun
        };
        const { data } = await window.axios.post(apiUrl, params);
        this.iframeUrl = `${window.emonev_api}${data.data.iframe_url}`;
        this.loadComponent = true;
      } catch (error) {
        this.loadComponent = false;
      }
    },
    async handleChangeTahun() {
      this.loadComponent = false;
      await this.generateUrl();
    },
    changeDataTooltip() {
      this.dataTooltip = true;
      setTimeout(() => {
        this.dataTooltip = false;
      }, 3000);
    }
  }
};
</script>
